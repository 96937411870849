<template>
	<div class="titleWrapper">
		<div class="title"  @click="trendFun"><slot></slot></div>
		<slot name="right"></slot>
		<div class="switch_button" v-if="isShowSwitch">
			<el-tooltip :content="'时间开关'" placement="right">
			<el-switch v-model="buttonvalue" active-color="#13ce66" inactive-color="grey" @change="change()">
				
			</el-switch>
		</el-tooltip>
		</div>
	</div>
</template>
<script>
export default {
	data() {
      return {
        buttonvalue: true,
				navArr: {
					"卫生资源": [4, 1], // 4-几个切换 1-当前页面排序位置
					"农村卫生": [3, 2],
					"病人医药费用": [3, 3],
					"中医药服务": [3, 4],
					"社区卫生": [2, 5],
					"妇幼卫生": [3, 6],
					"基础数据": [1, 1],
					"慢性病管理": [1, 2],
					"教育宣传": [1, 3],
					"服务体系": [2, 4],
					"工作指标": [2, 5],
					"业务培训": [2, 6],
					"医生团队": [1, 1],
					"居民健康管理" : [4, 2],
					"服务人群": [1, 3],
					"服务质量": [1, 4]
				},
      }
    },
	props:{
		isShowSwitch:{
			type:Boolean
		}
	},
	methods: {
		change(){
			this.$emit('changeSwitch',this.buttonvalue)
		},
		trendFun(e){
			let name = e.target.innerText
			let us = {
				name,
				index: this.navArr[name][0],
				seat: this.navArr[name][1]
			}
			this.$store.state.homePage.heads = us;
		}
	},
};
</script>
<style lang="scss" scoped>
.titleWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: url('../../../assets/imgs/titleBg.png') no-repeat center;
	background-size: 100% 100%;

	.radioGroup {
		margin-right: 12px;
	}
}

.title {
	line-height: 32px;
	padding-left: 16px;
	font-size: 16px;
	cursor: pointer;
}
.switch_button{
	margin-right: 15px;
}
</style>
