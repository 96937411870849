<template>
	<div class="bordered">
		<Title>优质项目</Title>
		<TotalAnalysis style="marginBottom: 0" :value="numberFun" genre="个"></TotalAnalysis>
		<BaseEchart :option="option" style="width: 100%; height: 340px"></BaseEchart>
	</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import Title from '../components/Title.vue';
import countTo from 'vue-count-to';
import BaseEchart from '@/components/BaseEchart';
import TotalAnalysis from '../components/TotalAnalysis.vue'
import { barChart } from '../common';
import { randomByLevel } from '@/views/homePage/common';
import { CountAnimationDuration } from '@/views/homePage/common';

export default {
	components: { Title, countTo, TotalAnalysis, BaseEchart},
	data() {
		return {
      CountAnimationDuration,
			number: 40759,
			essenArr: [{value: 95, name: '职业医师'}, {value: 56, name: '医护'}, {value: 99, name: '中医类别'}, {value: 88, name: '高级职称'}],
			option: null,
		};
	},
	computed: {
		...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
    numberFun(){
      return this.number / (this.mapLevel + 1)
    }
	},
  mounted() {
		this.init();
	},
	methods: {
		init() {
			let arry = ['设备', '中医药', '康养服务' , '人才培养']
      const dataX = arry.map((k,index) => {
        return {name: k, value: this.essenArr[index].value}
      })
      this.option = barChart({xData: dataX.map(d => d.name), yData: dataX.map(d => d.value)})
    },
	}
};
</script>
<style lang="scss" scoped>
@import '@/theme/bigScreen.scss';
.bordered{
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
}
.value {
	color: $main-text;
	font-size: 22px;
}
</style>