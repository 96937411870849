<!-- 中间地图数据 -->
<template>
  <div class="container">
    <div class="statistics">
      <div class="statistics-item" v-for="(item, index) in statisticsList" :key="item.label">
        <div class="line" v-if="index != 0"></div>
        <div class="label">{{ item.label }}</div>
        <div class="num">
          <countTo :startVal="0" :endVal="item.num" :duration="1000"></countTo>
        </div>
      </div>
    </div>
    <div class="map h100">
      <img v-if="showReturn" @click="toBackPre" class="toback" src="@/assets/imgs/toBack.png" alt="" />
      <component :mapType="mapType" :height="height" @showToBack="isShowReturnBtn" ref="map" :is="currenIndex"
        v-cloak />
    </div>
    <div class="tips">
      <img src="../../../../assets/imgs/megaphone.png" alt="" />
      <p>【2022-03-23 19:32】济南市历下区周有德上线啦济南市历下区马家庄村周有德上线啦~</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import sctterMap from '@/components/sctterMap';
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
import { random } from '@/utils';
export default {
  props: {
    height: {
      type: Number,
      default: 200,
    },
    mapType: {
      type: Number,
    },
  },
  components: {
    sctterMap,
    // VisitPlan,
    countTo,
  },
  data() {
    return {
      currenIndex: 'sctterMap',
      currentTownInfo: '',
      CountAnimationDuration,
      checkAddress: [],
      searchInfo: {
        searchKey: '',
        regionCode: '',
        type: '',
      },
      value: '',
      options: [
        { label: '巡诊', value: 'T' },
        { label: '驻派', value: 'D' },
      ],
      parentNode: [],
      showVisitPlan: false,
      optionProps: {
        value: 'code',
        label: 'title',
        children: 'areaList',
        emitPath: true,
        lazy: true,
        checkStrictly: true,
        lazyLoad: (node, resolve) => {
          const { level, value } = node; // 获取当前node对象中的level, value属性
          this.getArea(level, value, resolve);
        },
      },
      statisticsList: [
        { label: '基础数据', num: 0 },
        { label: '基层培训', num: 0 },
        { label: '服务与活动', num: 0 },
        { label: '资源统计', num: 0 },
        { label: '重点业务', num: 0 },
      ],
      showReturn: true, // 返回上一级
    };
  },
  computed: {
    ...mapState('mapData', ['mapLevel', 'mapDataArr', 'addressInfo']),
  },
  watch: {
    mapLevel: {
      handler(val) {
        let cityCode =
          JSON.parse(sessionStorage.getItem('userInfo')).regionCode != '0' ? JSON.parse(sessionStorage.getItem('userInfo')).regionCode : 100000;
        let level = cityCode == 100000 ? 0 : cityCode.length == 2 ? 1 : cityCode.length == 4 ? 2 : cityCode.length == 6 ? 3 : 4;
        this.showReturn = val > level ? true : false;
        if (val == 0) return (this.showReturn = false);
      },
      deep: true,
      immediate: true,
    },
    addressInfo: {
      handler(val) {
        if (val.regionCode) {
          let userCode = JSON.parse(sessionStorage.getItem('userInfo')).regionCode;
          this.showReturn = this.addressInfo.regionCode.length > userCode.length ? true : false;
        }
      },
      deep: true,
    },
    mapDataArr: {
      handler(val) {
        this.statisticsList = [
          { label: '基础数据', num: random(0, 50) },
          { label: '基层培训', num: random(0, 70) },
          { label: '服务与活动', num: random(0, 30) },
          { label: '资源统计', num: random(0, 100) },
          { label: '重点业务', num: random(0, 80) },
        ];
      },
    },
  },
  mounted() { },
  methods: {
    random,
    ...mapMutations('mapData', ['getMapFilter', 'getMapLevel']),
    // 返回镇
    backTown() {
      this.getMapLevel(3);
      this.$store.dispatch('mapData/setAddressInfo', this.currentTownInfo);
      this.$store.dispatch('mapData/setBaseData');
    },
    // 前往下一层
    // toLower(item) {
    // 	if (this.mapLevel == 3) {
    // 		this.currentTownInfo = this.addressInfo;
    // 	}
    // 	let data = {
    // 		cityCode: item.regionCode,
    // 		regionCode: item.regionCode,
    // 		name: item.regionName,
    // 		preName: this.addressInfo.name,
    // 		preCode: this.addressInfo.regionCode,
    // 	};
    // 	this.$store.dispatch('mapData/setAddressInfo', data);
    // 	this.$store.dispatch('mapData/setBaseData');
    // 	this.getMapLevel(this.mapLevel + 1);
    // },
    checkTown(item) {
      let data = {
        cityCode: item.regionCode,
        regionCode: item.regionCode,
        name: item.regionName,
        preName: this.addressInfo.name,
        preCode: this.addressInfo.regionCode,
      };

      this.$store.dispatch('mapData/setAddressInfo', data);
      this.getMapLevel(this.mapLevel + 1);
    },
    // 返回上一层
    toBackPre() {
      this.showVisitPlan = false;
      if (this.mapLevel == 5) {
        this.getMapLevel(4);
        return;
      }
      // if (this.$refs.cascader.inputValue) {
      // 	let inputValueList = this.$refs.cascader.inputValue.split('/');
      // 	inputValueList.length == 4 ? (inputValueList.length = 3) : '';
      // 	inputValueList.length == 5 ? (inputValueList.length = 4) : '';
      // 	inputValueList.pop();
      // 	this.$refs.cascader.inputValue = inputValueList.join('/');
      // }
      if (this.mapLevel == 4) {
        this.getMapLevel(3);
      }
      let flag = JSON.parse(sessionStorage.getItem('userInfo'))?.regionCode?.length != 6;
      this.$refs.map.toBackPre(flag);
    },
    // 搜索
    toSearch() {
      // 关键词搜索
      this.$store.dispatch('mapData/setBaseData');
      this.getMapFilter(this.searchInfo);
    },
    // 处理数字
    formatter(num) {
      if (num < 1000) return num;
      let src = String(num).split('');
      let b = [];
      while (true) {
        num = num / 1000;
        b.push(String(num).indexOf('.'));
        if (num < 1000) {
          break;
        }
      }
      for (var i = 0; i < b.length; i++) {
        src.splice(b[i], 0, ',');
      }
      return (src = src.join(''));
    },
    changeList(val) {
      if (val.type == this.currenIndex) {
        return;
      }
      this.currenIndex = val.type;
    },
    // 获取地区
    getArea(level, value, resolve) {
      let code = value ? value : null;
      this.$http
        .get(this.api['ChinaRegions#index'].href, { params: { code } })
        .then((res) => {
          if (res.data && res.data.success) {
            const nodes = res.data.collection;
            // level == 3 我这里是四级联动，在最后一级不需要下一级，层级从 0 开始
            if (level == 4) {
              nodes.forEach((item) => {
                // 当点击最后一级的时候 label 后面不会转圈圈 并把相关值赋值到选择器上
                item.leaf = level >= 1;
              });
            }
            resolve(nodes);
          }
        })
        .catch((err) => { });
    },
    // 递归取父节点
    getParentNode(node) {
      this.parentNode.push({
        cityCode: node.value.length <= 6 ? (node.value + '0000').substring(0, 6) : node.value,
        regionCode: node.value,
        name: node.label,
      });
      if (node.parent) {
        this.getParentNode(node.parent);
      }
    },
    handleChange(value) {
      if (value.length === 0) {
        this.checkAddress = [];
      }
      if (this.checkAddress.length > 4) {
        this.$refs['cascader'].dropDownVisible = false;
        this.showVisitPlan = true;
      }
      let checkNodes = this.$refs['cascader'].getCheckedNodes();
      if (checkNodes && checkNodes.length > 0) {
        this.parentNode = [];
        // 保存所有父节点到parentInfo，以便于返回
        checkNodes[0].parent ? this.getParentNode(checkNodes[0].parent) : '';
        let data = {
          cityCode: checkNodes[0].data.code,
          regionCode: checkNodes[0].data.code,
          name: checkNodes[0].data.title,
        };
        this.$refs.map.parentInfo = [
          {
            cityCode: 100000,
            name: '全国',
          },
          ...this.parentNode.reverse(),
        ];
        if (data.regionCode.length != 12) {
          this.showVisitPlan = false;
        }
        if (data.regionCode.length > 4) {
          //直接选择县城，则需要提前获取到上一层地图json
          let code = checkNodes[0].parent.label == '市辖区' ? data.cityCode.substring(0, 2) + '0000' : data.cityCode.substring(0, 4) + '00';
          this.$refs.map.getGeoJson(code, true);
        }
        if (data.regionCode.length <= 6) {
          this.$store.dispatch('mapData/setAddressInfo', data);
          data.cityCode = (data.cityCode + '0000').substring(0, 6);

          let level = 0;
          data.regionCode.length == 2 ? (level = 1) : data.regionCode.length == 4 ? (level = 2) : (level = 3);
          this.getMapLevel(level);

          this.$refs.map.getBaseData(data.cityCode);
          this.$refs.map.parentInfo.push(data);
        }
        if (data.regionCode.length == 9) {
          this.$refs.map.getGeoJson(data.cityCode.substring(0, 6));
          this.$refs.map.getBaseData(data.cityCode.substring(0, 6));
          this.$refs.map.parentInfo.push(data);
          this.getMapLevel(4);
          this.$store.dispatch('mapData/setAddressInfo', data);
          this.$store.dispatch('mapData/setBaseData');
          this.currentTownInfo = {
            cityCode: data.cityCode.substring(0, 6),
            regionCode: data.cityCode.substring(0, 6),
            name: checkNodes[0].parent.label,
          };
        } else {
          this.getMapLevel(5);
          this.$store.dispatch('mapData/setAddressInfo', data);
          this.$store.dispatch('mapData/setBaseData');
        }
        this.searchInfo.regionCode = value[4];
        this.getMapFilter(this.searchInfo);
      } else {
        this.$store.dispatch('mapData/setAddressInfo', { cityCode: 100000, name: '全国' });
        this.getMapLevel(0);
        this.$refs.map.getBaseData(100000);
        this.checkAddress = [];
        this.$refs.map.parentInfo = [
          {
            cityCode: 100000,
            name: '全国',
          },
        ];
      }
    },
    isShowReturnBtn() { },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .statistics {
    background: url('../../../../assets/imgs/totalBgTop.png') no-repeat center;
    background-size: 100% 100%;
    height: 100px;
    padding: 20px 44px;
    display: flex;
    justify-content: space-between;

    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .label {
        font-size: 16px;
      }

      .num {
        color: #0bf9fe;
        font-weight: bold;
        font-style: italic;
        font-size: 26px;
      }

      .line {
        width: 1px;
        height: 29px;
        border: 1px solid #274574;
        position: absolute;
        left: -60px;
        top: 10px;
      }
    }
  }

  .map {
    margin: 16px 0;
    position: relative;

    .listCardWrapper {
      width: 100%;
      height: 600px;
      overflow-y: auto;
      position: absolute;
      left: 0;
      top: 66px;
      background-color: #041735;
    }

    .listCard {
      display: flex;
      flex-wrap: wrap;
      padding: 12px;

      .card {
        height: 116px;
        width: 23%;
        display: flex;
        margin: 0 1% 12px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url('../../../../assets/imgs/noCheck.png');
        background-size: 100% 100%;
        cursor: pointer;

        .title {
          color: #71bbff;
          font-size: 14px;
        }

        .line {
          width: 148px;
          height: 1px;
          background: linear-gradient(270deg, rgba(94, 177, 252, 0) 0%, #2e80c9 45%, rgba(92, 177, 253, 0) 100%);
        }

        .content {
          width: 90%;
          display: flex;
          justify-content: space-around;

          .content-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .name {
              font-size: 12px;
              color: #c4e1fb;
            }

            .value {
              font-weight: bold;
              color: #00ebfa;
              font-size: 16px;
            }
          }
        }
      }
    }

    .toback {
      width: 22px;
      height: 22px;
      top: 30px;
      position: absolute;
      left: 15px;
      z-index: 10;
      cursor: pointer;
    }

    /deep/ .el-cascader {
      position: absolute;
      left: 50px;
      width: 258px;
      top: 20px;
      color: #fff;
      z-index: 10;

      .el-input__inner {
        height: 42px;
        line-height: 42px;
        border-radius: 0;
        border: 2px solid #1e395e;
        background-color: #031d40;
        box-shadow: inset 0 0 10px -1px #189ee5;
        color: #fff;
      }
    }

    /deep/ .el-select {
      position: absolute;
      right: 15px;
      width: 139px;
      top: 20px;
      color: #fff;
      z-index: 10;

      .el-input__inner {
        height: 42px;
        line-height: 42px;
        border-radius: 0;
        border: 2px solid #1e395e;
        background-color: #031d40;
        box-shadow: inset 0 0 10px -1px #189ee5;
        color: #fff;
      }

      .el-select-dropdown {
        z-index: 9999;
      }
    }

    .search {
      z-index: 11;
      position: absolute;
      right: 15px;
      width: 258px;
      top: 20px;
      box-shadow: inset 0 0 10px -1px #189ee5;
      border: 2px solid #1e395e;
      padding: 4px;
      box-sizing: border-box;
      background-color: #031d40;

      /deep/ .el-input {
        color: #fff;
        z-index: 11;

        .el-input__inner {
          border-radius: 0;
          border: none;
          background-color: #031d40;
          color: #fff;
        }

        .el-input-group__append {
          background-color: #031d40;
          border: none;
          font-size: 16px;
          color: #69c0ef;
        }
      }
    }

    .area {
      position: absolute;
      bottom: 20px;
      right: 20px;
      background: url('../../../../assets/imgs/area.png') no-repeat center;
      background-size: 100% 100%;
      height: 263px;
      width: 216px;
      // padding: 20px 44px;
      font-size: 12px;

      .area-head {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .triangle {
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 8px 5px 0;
        border-color: transparent #fff transparent transparent;
        position: absolute;
        left: 12px;
        top: 16px;
        transform: translate(0, -50%);
        cursor: pointer;
      }

      .area-content {
        width: 100%;
        height: 225px;
        padding: 11px 7px;
        overflow-y: scroll;
        display: flex;
        flex-wrap: wrap;

        .item {
          padding: 0 10px;
          height: 32px;
          margin: 5px 4px;
          display: flex;
          align-items: center;
          border: 1px solid #0d336a;
          // border-color: none;
          cursor: pointer;
        }

        .item:hover {
          background: linear-gradient(180deg, #3467d0, rgba(50, 105, 213, 0) 50%, #376fe0 100%);
          border: 1px solid;
          height: 32px;
          border-image: linear-gradient(270deg, #4b8dde 0%, #0d1d3e 50%, #4c8edf) 1 1;
        }
      }
    }
  }

  .tips {
    background: rgba(5, 41, 92, 0.6);
    display: flex;
    border: 2px solid #264471;
    padding: 16px 20px;
    font-size: 16px;
    height: 60px;

    img {
      width: 24px;
      height: 24px;
    }
  }
}

/deep/ div::-webkit-scrollbar {
  width: 4px !important;
  /*滚动条宽度*/
  background-color: #031227 !important;
  /*滚动条背景颜色*/
  height: 394px !important;
  /*滚动条高度*/
}

/deep/ div::-webkit-scrollbar-thumb {
  box-shadow: 0px 1px 3px #203a66 inset !important;
  /*滚动条的内阴影*/
  border-radius: 10px !important;
  /*滚动条的圆角*/
  background-color: #203a66 !important;
  /*滚动条的背景颜色*/
}
</style>