<template>
  <div ref="container" class="importantKey w100">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch">社区卫生</Title>
    <div v-if="cutIdx == 0">
      <TotalAnalysis style="marginBottom: 0" :value="valueByLevel" :patter="tit"></TotalAnalysis>
      <div class="m_xares">
        <div class="line" v-for="(v, i) in essenFun" :key="i">
          <p>{{ v.name }}</p>
          <div class="value"><countTo :startVal="0" :endVal="v.value" :duration="1000"></countTo></div>
        </div>
      </div>
    </div>
    <div class="m_xpoten" v-if="cutIdx == 1">
      <div class="rows" v-for="(v, i) in healthFun" :key="i">
        <p>{{ v.name }}<countTo :startVal="0" :endVal="v.value" :duration="1000"></countTo></p>
        <p>{{ v.cas }}<countTo :startVal="0" :endVal="v.num" :duration="1000"></countTo></p>
      </div>
    </div>
    <div class="m_xcut">
      <em v-for="(v, i) in cutArr" :key="i" :class="{'on': i == cutIdx}" @click="cutFun(i)">{{v}}</em>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { barChart } from '../common'
import countTo from 'vue-count-to';
import { randomByLevel } from '@/views/homePage/common';
import TotalAnalysis from '../components/TotalAnalysis.vue'
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import StatisticsCard from '../components/StatisticsCard'

export default {
  components: {
    StatisticsCard,
    BaseEchart,
    Title,
    countTo,
		TotalAnalysis
  },
  data() {
    return {
      option: null,
			analysisNum: 37177,
      showNoData: false,
      cardData: [{ name: '总人数', value: 830000 }, { name: '老人', value: 1025 }, { name: '妇女', value: 825 }, { name: '儿童', value: 2042 }, { name: '常见病', value: 159 }],
      cutIdx: 0,
      cutArr:['社区卫生体系', '社区医疗'],
      tit: {
        type: 2,
        title: '全国社区（站数）'
      },
      essenArr: [
        {value: 10070, name: '社区卫生中心数'}, 
        {value: 27107, name: '社区卫生站数'},
        {value: 627000, name: '社区卫生中心人员数'},
        {value: 62, name: '平均每个中心人数'},
        {value: 151000, name: '社区卫生站人员数'},
        {value: 6, name: '平均每站人数'},
      ],
      health:[{
        name: '社区卫生中心提供诊疗人次数',
        value: 1170,
        num: 1165,
        cas: '入院人数'
      },{
        name: '平均每个中心年诊疗人次数',
        value: 1300,
        num: 1200,
        cas: '年入院人数'
      },{
        name: '医师日均担负诊疗人次数',
        value: 2070,
        num: 1065,
        cas: '医师日均担负住院日'
      },{
        name: '社区卫生站提供诊疗人次数',
        value: 2070,
        num: 1065,
        cas: '社区日均担负住院日',
       
      }],
      isShowSwitch:true,
      isSwitchOpen:true,
      period:1
    };
  },
  computed: {
    ...mapState('mapData', ['addressInfo', 'mapLevel', 'mapDataArr']),
		valueByLevel(){
      return (this.analysisNum / (this.mapLevel + 1))/this.period
    },
    essenFun(){
      return this.essenArr.map(s => {
        return {
          ...s,
          value: (s.value / (this.mapLevel + 1))/this.period
        }
      })
    },
    healthFun(){
      return this.health.map(s => {
        return {
          ...s,
          num: (s.num / (this.mapLevel + 1))/this.period,
          value: (s.value / (this.mapLevel + 1))/this.period
        }
      })
    },
    currentMonthPeriod(){
      return this.$store.state.homePage.currentMonthPeriod
    },
    monthPeriod(){
      return this.$store.state.homePage.monthPeriod
    },
  },
  watch: {
    mapDataArr() {
      const arrData = this.mapDataArr.map((d) => {
        return { name: d.regionName, value: randomByLevel({ max: 1000, level: this.mapLevel }) };
      })
      this.option = barChart({ xData: arrData.map(a => a.name), yData: arrData.map(a => a.value) }, {max: 1000})
    },
    monthPeriod:{
     handler(){
      this.selectTime()
     },
    }
  },
  mounted() {
    this.selectTime()
  },
  methods: {
    cutFun(i) {
      this.cutIdx = i
      let num = 0;
      if(i == 0){
        num = this.valueByLevel
      }else{
        num = this.healthFun[0].value
      }
      this.$store.state.homePage.block.commHealth = num
      const arrData = this.mapDataArr.map((d) => {
        return { name: d.regionName, value: randomByLevel({ max: 1000, level: this.mapLevel }) };
      })
      this.option = barChart({ xData: arrData.map(a => a.name), yData: arrData.map(a => a.value) }, {max: 1000})
    },
    changeSwitch(val){
      this.isSwitchOpen=val
      this.selectTime()
    },
    selectTime(){
      if(this.cutIdx == 0){
        this.$store.state.homePage.block.commHealth = this.valueByLevel
      }
      if(this.isSwitchOpen){
        this.period=this.monthPeriod
      }else{
        this.period=this.currentMonthPeriod
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.importantKey {
  border: 2px solid #264471;
  box-sizing: border-box;
  position: relative;
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);
  .nullData {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.m_xcut{
  height: 35px;
  display: flex;
  align-items: center;
  border-top: 1px solid #213861;
  em{
    flex: 1;
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    font-style: normal;
    text-align: center;
    line-height: 35px;
    position: relative;
    cursor: pointer;
  }
}
.m_xcut em:not(:first-child)::before{
  position: absolute;
  content: '';
  left: 0;
  width: 1px;
  height: 12px;
  top:50%;
  margin-top: -6px;
  background: #213861;
}
.m_xcut .on{
  color:#00F0FF;
}
.m_xares{
	padding: 0 15px 10px;
	text-align: center;
	.line{
		width: 50%;
		height: 70px;
		background: rgba(28, 135, 255, .1);
		margin: 12px 0 0;
    display: inline-block;
    position: relative;
	}
	p{
		font-size: 16px;
		color:#fff;
		padding: 14px 0 0;
	}
  .crew{
    display: inline-block;
    width: 76px;
    height: 76px;
    margin: 30px 0 10px;
  }
  .value{
    font-size: 20px;
    font-weight: 500;
    color: #00FFFF;
  }
}
.m_xares .line:not(:nth-child(2n+1))::before{
  position: absolute;
  content: '';
  left: 0;
  width: 1px;
  height: 12px;
  top:50%;
  margin-top: -6px;
  background: #213861;
}
.m_xpoten{
  height: 340px;
  overflow-y: auto;
  .rows{
    background: rgba(28, 135, 255, .1);
    padding: 0 20px 10px;
    margin: 16px;
  }
  p{
    font-size: 14px;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    position: relative;
    padding: 12px 0 0 16px;
  }
  span{
    color:#00FFFF;
    font-size: 20px;
    font-weight: bold;
    padding-left: 16px;
  }
}
.m_xpoten p::before{
  content: '';
  position: absolute;
  width: 5px;
  height: 5px;
  background: #46FCFF;
  border-radius: 50%;
  left: 0;
  top:22px;
  box-shadow: 0 0 5px#00F0FF;
}
</style>