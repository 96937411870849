<template>
  <div class="basicMedical">
    <Title :isShowSwitch="isShowSwitch" @changeSwitch="changeSwitch">
      中医药服务
    </Title>
    <div v-if="serviceIndex == 1">
      <BaseEchart :option="option" style="width: 100%; height: 225px"></BaseEchart>
    </div>
    <div v-if="serviceIndex == 2">
      <BaseEchart :option="option2" style="width: 100%; height: 225px"></BaseEchart>
    </div>
    <div v-if="serviceIndex == 3" style="display: flex;justify-content: space-evenly;height: 225px;align-items: center;">
      <div v-for="item in workloadByLevel" class="workload">
        <p class="left_top">{{ item.name }}</p>
        <img src="../../../assets/imgs/icon5.png" alt="">
        <p class="line"></p>
        <p class="content">日均担负诊疗人次 <span>
            <countTo :startVal="0" :endVal="item.value1" :duration="CountAnimationDuration"></countTo>
          </span></p>
        <p class="content">住院床日<span>
            <countTo :startVal="0" :endVal="item.value2" :duration="CountAnimationDuration"></countTo>
          </span></p>
      </div>
    </div>
    <div class="tab">
      <span :class="serviceIndex == 1 ? 'on' : ''" @click="selectService(1)">机构数</span>
      <em>|</em>
      <span :class="serviceIndex == 2 ? 'on' : ''" @click="selectService(2)">床位数</span>
      <em>|</em>
      <span :class="serviceIndex == 3 ? 'on' : ''" @click="selectService(3)">人员数</span>
    </div>
  </div>
</template>

<script>
import BaseEchart from '@/components/BaseEchart';
import Title from '../components/Title';
import { mapState } from 'vuex';
import countTo from 'vue-count-to';
import { CountAnimationDuration } from '@/views/homePage/common';
import { fetchRegionSummaryNotations, mapDataNormal } from '../../../request/api';
export default {
  components: { BaseEchart, Title, countTo },
  data() {
    return {
      CountAnimationDuration,
      option: undefined,
      option2: undefined,
      data: [
        { value: 6175, name: '中医类医院', per: 0 },
        { value: 86317, name: '中医类门诊部', per: 0 },
        { value:39, name: '中医类研究机构', per: 0 },
        // { value: 0, name: '卫生室诊疗总人数', per: 0 },
        // { value: 0, name: '诊所诊疗总人数', per: 0 },
        // { value: 0, name: '其他诊疗总人数', per: 0 },
      ],
      dataBar: [
        {
          name: '全国医疗机构病床使用率',
          value: 50,
          unit: 'Kwh',
        },
        {
          name: '医院',
          value: 72,
          unit: 'Kwh',
        },
        {
          name: '卫生院',
          value: 65,
          unit: 'Kwh',
        },
      ],
      workload: [
        { name: '医院医师', value1: 675, value2: 30 },
        { name: '卫生院医师', value1: 136, value2: 30 },
      ],
      serviceIndex: 1,
      isShowSwitch: true,
      isSwitchOpen: true,
      period: 1,
      pieTotal: 92531
    };
  },
  computed: {
    ...mapState('mapData', ['mapLevel', 'addressInfo', 'mapDataArr']),
    dataByLevel() {
      return this.data.map(item => {
        return {
          ...item,
          value: Math.round((item.value / (this.mapLevel + 1)) / this.period)
        }
      })
    },
    // pieTotal() {
    //   let sum = 0
    //   this.data.map(item => {
    //     sum += Math.round(item.value)
    //   }
    //   )
    //   return sum
    // },
    dataBarByLevel() {
      return this.dataBar.map(item => {
        return {
          ...item,
          value: ((item.value - (this.mapLevel + 1) * 3) / this.period).toFixed(2)
        }
      })
    },
    workloadByLevel() {
      return this.workload.map(item => {
        return {
          ...item,
          value1: ((item.value1 - (this.mapLevel + 1) * 3) / this.period),
          value2: ((item.value2 - (this.mapLevel + 1) * 2) / this.period),
        }
      })
    },
    currentMonthPeriod() {
      return this.$store.state.homePage.currentMonthPeriod
    },
    monthPeriod() {
      return this.$store.state.homePage.monthPeriod
    },
  },
  watch: {
    addressInfo: {
      handler() {
        if (this.addressInfo.regionCode) {
          this.selectTime()
        }
      },
    },
    monthPeriod: {
      handler() {
        this.selectTime()
      },
    }
  },
  methods: {
    async fetchBasicData() {
      const data = await fetchRegionSummaryNotations({ 'itemName': '中医类医院;中医类门诊部;中医类研究机构;', fullTime: this.isSwitchOpen })
      // const data = await fetchRegionSummaryNotations({ 'itemName': '县医院诊疗总人数;乡卫生院诊疗总人数;社区中心诊疗总人数;卫生室诊疗总人数;诊所诊疗总人数;其他诊疗总人数', fullTime: this.isSwitchOpen })
      // let res = data.data.collection
      // if(res.length>0){
      // let bars = mapDataNormal(this.data, data?.data?.collection || []);
      // this.data = bars
      let num = 0
      this.data.forEach(item => {
        num += item.value
      })
      this.pieTotal = num
      // this.pieTotal = num
      this.$store.state.homePage.block.medService = num
      //  this.data=res.map(item=>{
      //   return { name: item.name, value: item.intValue};
      //   })
      // }
    },
    selectService(i) {
      this.serviceIndex = i
      if(i==1){
         this.init()
      }else if(i==2){
        this.initBar()
      }else{
        this.$store.state.homePage.block.medService = this.workloadByLevel[0].value1
      }
    },
    async init() {
      await this.fetchBasicData()
      let colorOption = ['#FBD75B', '#4EF5FF', '#4BFDCF', '#307BE3']
      let titleOption = '机构总数'
      this.option = {
        color: colorOption,
        // tooltip: {
        //   trigger: 'item',
        // },
        legend: {
          show: false,
          bottom: 0,
          padding: [0, 0, 20, 0],
          formatter: function (name) {
            return '{a|' + name + '}'
          },
          icon: 'circle',
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            color: 'white',
            backgroundColor: 'transparent',
            rich: {
              a: {
                width: 80,
                fontSize: 14
              },
            },
          },
        },
        grid: {
          top: 0,
        },
        graphic: {
          elements: [
            {
              type: 'circle',
              shape: {
                r: 80,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 40,
            },
            {
              type: 'circle',
              shape: {
                r: 47,
              },
              style: {
                stroke: '#307BE3',
                lineWidth: 1,
              },
              left: 'center',
              top: 70,
            },
            {
              type: 'image',
              style: {
                image: require('../assets/pie-bg2.jpg'),
                width: 80,
                height: 80,
              },
              left: 'center',
              top: 78,
            },
            {
              type: 'text',
              left: '43.5%',
              top: 100,
              style: {
                fill: '#ffffff',
                text: titleOption,
                font: '14px sans-serif',
                textAlign: 'center',

              },
            },
            {
              type: 'text',
              left: 'middle',
              bounding: 'raw',
              left: 'center',
              top: 120,
              style: {
                fill: '#00F7F9',
                text: this.pieTotal,
                font: '22px sans-serif',
                textAlign: 'center',
              },
            },
          ],
        },
        series: [
          {
            type: 'pie',
            top: 12,
            left: 'center',
            width: 260,
            radius: ['50%', '66%'],
            avoidLabelOverlap: true,
            itemStyle: {
              borderRadius: 20,
              borderColor: '#020C1D',
              borderWidth: 3,
            },
            label: {
              // alignTo: 'edge',
              formatter: '{name|{b}}\n{value|{c}}  {per|{d}%}',
              minMargin: 5,
              edgeDistance: 50,
              // margin:'-50',
              lineHeight: 12,
              rich: {
                name: {
                  fontSize: 14,
                  color: '#fff'
                },
                value: {
                  fontSize: 12,
                },
              },
            },
            labelLine: {
              normal: {
                length: 5,
                length2: 25,
              }
            },
            data: this.data,
          },
        ],
      };
    },
    initBar() {
      let color = ['#4EF5FF', '#F9B37A', '#FBE06C'];
      let chartData = this.dataBarByLevel
      let sum = 100;
      let pieSeries = [];
      let lineYAxis = [];
      chartData.sort((a, b) => b.value - a.value).forEach((v, i) => {
        pieSeries.push({
          name: '分布情况',
          type: 'pie',
          left: -200,
          clockWise: false,
          hoverAnimation: false,
          radius: [75 - i * 15 + '%', 67 - i * 15 + '%'],
          center: ['50%', '50%'],
          label: {
            show: false,
          },
          data: [
            {
              value: v.value,
              name: v.name,
            },
            {
              value: sum - v.value,
              name: '',
              itemStyle: {
                color: 'rgba(0,0,0,0)',
              },
            },
          ],
        });
        pieSeries.push({
          name: '用电量分布情况',
          type: 'pie',
          silent: true,
          z: 1,
          left: -200,
          clockWise: false,
          hoverAnimation: false,
          radius: [75 - i * 15 + '%', 67 - i * 15 + '%'],
          center: ['50%', '50%'],
          label: {
            show: false,
          },
          data: [
            {
              value: 1,
              itemStyle: {
                color: '#172F6F',
              },
            },
          ],
        });
        v.percent = ((v.value / sum) * 100).toFixed(1) + '%';
        lineYAxis.push({
          value: i,
          textStyle: {
            rich: {
              circle: {
                color: color[i],
                padding: [0, 5],
              },
            },
          },
        });
      });
      this.$store.state.homePage.block.medService=parseInt(chartData[0].value)
      const legendData = chartData.map(d => d.name).slice()
      legendData.splice(2, 0, '')
      this.option2 = {
        tooltip: {},
        legend: {
          data: legendData,
          left: 220,
          top: 100,
          formatter: (name) => {
            const percent = chartData.find(d => d.name === name).value
            const idx = chartData.findIndex(d => d.name === name)
            return `{name|${name}} {${idx}|${percent}%}`;
          },
          textStyle: {
            color: 'white',
            rich: {
              name: {
                fontSize: 12,
                width: 145
              },
              percent: {
                color: '#ff00ff'
              },
              "1": {
                color: color[1]
              },
              "2": {
                color: color[2]
              },
              "0": {
                color: color[0]
              }
            }
          },
        },
        color: color,
        grid: {
          bottom: '60%',
          containLabel: true
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            data: lineYAxis,
          },
        ],
        xAxis: [
          {
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            show: false,
          },
        ],
        series: pieSeries,
      };
    },
    changeSwitch(val) {
      this.isSwitchOpen = val
      this.selectTime()
    },
    selectTime() {
      if (this.isSwitchOpen) {
        this.period = this.monthPeriod
      } else {
        this.period = this.currentMonthPeriod
      }
      this.init()
      this.initBar()
    }
  },
  mounted() {
    this.init()
  },
};
</script>

<style lang="scss" scoped>
.basicMedical {
  border: 2px solid #264471;
  box-sizing: border-box;
  background: rgba(5, 41, 92, .6);
  border: 2px solid rgba(17, 94, 205, .2);

  #basicMedicalData {
    background: linear-gradient(to bottom, #0a2a57, #092448);
  }

  .tab {
    width: 100%;
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    border-top: 1px solid #213861;
    display: flex;

    span {
      flex: 1;
      display: inline-block;
      height: 100%;
      position: relative;
      cursor: pointer;
    }

    em {
      font-style: normal;
      color: #213861;
    }

    .on {
      color: #00F0FF;
    }
  }

  .workload {
    width: 186px;
    height: 200px;
    background-color: #0D366D;
    display: flex;
    flex-direction: column;

    img {
      width: 70px;
      height: 70px;
      margin: 10px auto;
    }

    .left_top {
      background-color: #1C87FF;
      width: 80px;
      height: 20px;
      line-height: 20px;
      font-size: 14px;
      font-weight: 400;
      text-indent: 1em;
    }

    .line {
      width: 60px;
      height: 1px;
      background-color: #26477A;
      margin: 10px auto;
    }

    .content {
      width: 85%;
      font-size: 14px;
      font-weight: 400;
      margin: 5px auto;
      display: flex;
      justify-content: space-between;

      span {
        color: #00FFFF;
        font-size: 18px;
      }
    }
  }
}</style>
